import React, { ReactElement } from 'react'
import { useTheme } from '@material-ui/core/styles'

import Carousel from '@objects/carousel'
import Teaser from '@objects/teaser'

import { RelatedContentTypes } from '../'

type TeaserRowProps = {
  relatedContent: RelatedContentTypes[]
}

export default function Default({
  relatedContent,
}: TeaserRowProps): ReactElement {
  const theme = useTheme()
  return (
    <Carousel
      slidesPerView={1.5}
      spaceBetween={24}
      breakpoints={{
        [theme.breakpoints.values.md]: {
          slidesPerView: 2.5,
          spaceBetween: 20,
        },
        [theme.breakpoints.values.lg]: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
      }}
    >
      {relatedContent.map((content: RelatedContentTypes, i: number) => (
        <Teaser
          key={`default-teaser-${i}`}
          headline={content.teaserTitle}
          copy={content.teaserCopy}
          image={content.teaserImage}
          fullPath={content.fields?.fullPath}
        />
      ))}
    </Carousel>
  )
}
