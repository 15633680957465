import React, { ReactElement, useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Teaser from '@objects/teaser'

import { RelatedContentTypes } from '../'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginLeft: theme.spacing(-2),
  },
  teaserWrapper: {
    padding: theme.spacing(0, 2),
    flexShrink: 0,
    transitionProperty: 'width',
    transitionTimingFunction: theme.transitions.easing.easeOut,
  },
  teaser: {
    position: 'relative',
    overflow: 'hidden',
  },
}))

type TeaserRowProps = {
  relatedContent: RelatedContentTypes[]
}

export default function Fan({ relatedContent }: TeaserRowProps): ReactElement {
  const theme = useTheme()
  const classes = useStyles()
  const [current, setCurrent] = useState(0)
  const DURATION =
    theme.transitions.duration.shorter + relatedContent.length * 50
  const ACTIVE_ITEM_WIDTH = '365px'
  const INACTIVE_ITEM_WIDTH = `calc((${
    theme.container.lg
  } - ${ACTIVE_ITEM_WIDTH} - ${theme.spacing(4)}px) / ${
    relatedContent.length - 1
  })`

  return (
    <section className={classes.root}>
      {relatedContent.map((content: RelatedContentTypes, i: number) => {
        const width = current === i ? ACTIVE_ITEM_WIDTH : INACTIVE_ITEM_WIDTH

        return (
          <div
            key={`default-teaser-${i}`}
            className={classes.teaserWrapper}
            style={{ width, transitionDuration: `${DURATION}ms` }}
          >
            <div onMouseEnter={() => setCurrent(i)}>
              <Teaser
                headline={content.teaserTitle}
                copy={content.teaserCopy}
                image={content.teaserImage}
                fullPath={content.fields?.fullPath}
                className={classes.teaser}
                current={current === i}
                transitionDelay={DURATION}
                interactive
                hasTriangle
              />
            </div>
          </div>
        )
      })}
    </section>
  )
}
