import React, { ReactElement } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Headline from '@objects/headline'
import Container from '@objects/container'
import InViewAnimation from '@objects/inViewAnimation'

import Default from './variants/default'
import Fan from './variants/fan'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: ({ isDarkTheme }: { isDarkTheme: boolean }) =>
      isDarkTheme
        ? theme.palette.background.greyDark
        : theme.palette.background.default,
    color: ({ isDarkTheme }: { isDarkTheme: boolean }) =>
      isDarkTheme ? theme.palette.text.invert : theme.palette.text.primary,
    padding: theme.spacing(8, 0, 10, 0),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(15, 0, 20, 0),
    },
    '& .swiper-slide': {
      display: 'flex',
      alignItems: 'stretch',
      height: 'auto',
    },
  },
}))

export type RelatedContentTypes = {
  teaserCopy: RvG.Contentful.BasicRichTextType
  teaserImage?: RvG.Contentful.IAsset
  teaserTitle: string
  fields: {
    fullPath: string
  }
}

export type TeaserRowProps = RvG.IReactDefaultProps & {
  headline: string
  theme: 'Dark' | 'Light'
  type: string
  relatedContent: RelatedContentTypes[]
}

export default function TeaserRow({
  type,
  headline,
  theme,
  relatedContent,
}: TeaserRowProps): ReactElement {
  const isDarkTheme = theme === 'Dark'
  const classes = useStyles({ isDarkTheme })
  const muiTheme = useTheme()
  const isLarge = useMediaQuery(muiTheme.breakpoints.up('lg'))

  function InnerComponent() {
    switch (type) {
      case 'Default':
        return <Default relatedContent={relatedContent} />
      default:
        return isLarge ? (
          <Fan relatedContent={relatedContent} />
        ) : (
          <Default relatedContent={relatedContent} />
        )
    }
  }

  return (
    <section className={classes.root}>
      <Container type="nomargin">
        <InViewAnimation
          group={[
            <Headline key={`teaserrow-headline`}>{headline}</Headline>,
            <InnerComponent key={`teaserrow-innerComponent`} />,
          ]}
        />
      </Container>
    </section>
  )
}
